import http from '@api/http';
import { errorTracker, isAbortError } from '@utils/errorTracker';

export const fetchPermissions = async (signal?: AbortSignal) => {
  try {
    const { data } = await http.get(`/kpis/permission`, { signal });
    return data;
  } catch (error: any) {
    if (!isAbortError(error)) {
      errorTracker(error);
    }
    throw error;
  }
};
