import { CONFIG } from '@library/config';
const { PDFJS_CONFIG } = CONFIG;

export const SetupPDFJS = async () => {
  const { pdfjs } = await import('react-pdf');
  const config = (PDFJS_CONFIG ?? '').split(';');

  const useExternalWorkerSource =
    config.includes('useExternalWorkerSource') || false;

  const usePdfPort = config.includes('usePdfPort') || false;

  const workerPath = useExternalWorkerSource
    ? `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`
    : new URL(
        'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
        import.meta.url
      ).toString();

  if (usePdfPort) {
    const PDFWorker = await import(
      'pdfjs-dist/legacy/build/pdf.worker.min.mjs'
    );
    const pdfWorker = new PDFWorker();
    pdfjs.GlobalWorkerOptions.workerPort = pdfWorker.port;
  } else {
    pdfjs.GlobalWorkerOptions.workerSrc = workerPath;
  }
};
